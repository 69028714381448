/* Footer styles */
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: calc(100% - 72px); /* Adjusted width to account for drawer width */
  margin-left: 72px; /* Align with the collapsed drawer */
  background-color: #ffffff;
  padding: 20px 20px;
  border-top: 1px solid rgba(39, 37, 37, 0.21);
  box-sizing: border-box; /* Include padding in the width */
  transition: margin-left 0.3s; /* Smooth transition for alignment */
}

.footer-paragraph {
  color: #000000;
  font-size: 12px;
  margin: 0; /* Remove default margin */
}

.footer-pages {
  text-decoration: none;
  color: #000000;
}

/* Flexbox alignment for the footer content */
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Media query for responsiveness */
@media (min-width: 765px) {
  .footer-copyright {
    text-align: right;
  }
}

.footer-expanded {
  margin-left: 240px; /* Adjust the margin to match the expanded drawer width */
  transition: margin-left 0.3s;
}

.footer-collapsed {
  margin-left: 72px; /* Adjust the margin to match the collapsed drawer width */
  transition: margin-left 0.3s;
}