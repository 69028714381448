.Guidelines {
  margin-top: 80px;
}

h2 {
  color: #000;
}

p {
  color: #000;
}